.btn-primary {
  @apply py-2 px-24 bg-gaucher text-white text-center font-semibold text-sm rounded-full drop-shadow-xl hover:bg-gaucher focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;

  position: relative;
  text-transform: uppercase;

  &::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 58px;
    height: 46px;
    background-image: url(./assets/buttonleft.png);
    background-size: contain;
  }

  &::after {
    position: absolute;
    content: "";
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 58px;
    height: 46px;
    background-image: url(./assets/buttonleft.png);
    background-size: contain;
  }

  &.disabled {
    opacity: .5;
  }
}

.btn-secondary {
  @apply py-2 px-8 bg-white uppercase text-gray-800 text-center font-semibold text-sm rounded-full drop-shadow-xl hover:bg-white focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-opacity-75;

  position: relative;
  text-transform: uppercase;

  &:disabled {
    opacity: .4;
  }
}

.text-rid {
  font-size: 0.3rem;
}

::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar:window-inactive {
  display: none;
}

::-webkit-scrollbar:corner-present {}

::-webkit-scrollbar-track-piece {
  border-radius: 2px;
  background-color: #369;
  background-clip: content-box;
  border-radius: 6px;
  border: solid 4px transparent;
}

::-webkit-scrollbar-thumb {
  height: 10px;
  width: 4px;
  background-color: #ffffff;
  border-radius: 6px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

::-webkit-scrollbar-track-piece:no-button {
  background-color: #ffffff;
}

.dark-blur {
  @apply backdrop-blur-lg;
  background-color: rgba(0, 0, 0, 0.5);
}

.white-blur {
  @apply backdrop-blur-lg;
  background-color: rgba(255, 255, 255, 0.2);
}

.window {
  @apply flex-1;
  // position: absolute;
  // top: 0;
  // left: 280px;
  // right: 280px;
  // bottom: 40px;
  z-index: 3;
  overflow: hidden;
  opacity: 1;
  transition: all 0.4s ease-in-out;

  &.loading {
    opacity: 0;
  }

  &::before {
    @apply bg-gradient-to-b from-white/60 via-white/10 to-transparent;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 40px;
  }

  .header {
    @apply flex flex-row justify-between items-center h-14;

    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding-left: 10px;

    .dot {
      margin-left: 10px;
      width: 14px;
      height: 14px;
      border-radius: 14px;
      background-color: white;
    }
  }

  .body {}

  .footer {
    height: 3.5rem;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
  }
}

.backdrop {
  z-index: 1000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &._hidden {
    opacity: 0;
    visibility: hidden;
  }

  &.bg-transparent {
    background-color: transparent;
  }
}

.customModal,
.codeModal,
.referencesModal,
.rightModal {
  .close {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 32px;
    height: 32px;
    opacity: 0.8;
    cursor: pointer;
  }

  .close:hover {
    opacity: 1;
  }

  .close:before,
  .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #ffffff;
  }

  .close:before {
    transform: rotate(45deg);
  }

  .close:after {
    transform: rotate(-45deg);
  }
}

.customModal {
  @apply aspect-[20/17];
  position: absolute;
  z-index: 1010;
  bottom: 0;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  background: url(https://ubelong-resources.s3.eu-west-3.amazonaws.com/drgaucher/mvp/modal.svg) top center no-repeat;
  background-size: cover;
  transition: all 0.3s ease-in-out;

  &._hidden {
    bottom: -100%;
    top: 100%;
  }

  .content {
    position: absolute;
    background-position: center center;
    background-repeat: no-repeat;
    mix-blend-mode: multiply;
    background-size: contain;
    top: 7%;
    left: 7%;
    right: 7%;
    bottom: 30px;
  }
}

.codeModal {
  @apply backdrop-blur-lg;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  z-index: 1010;
  top: 0;
  left: 0;
  right: 0;
  height: 220px;
  transition: all 0.3s ease-in-out;

  .close {
    top: 15px;
    right: 15px;
  }

  &._hidden {
    top: -220px;
  }
}

.referencesModal {
  @apply backdrop-blur-lg;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  z-index: 1010;
  top: 0;
  left: 0;
  bottom: 0;
  width: 440px;
  transition: all 0.3s ease-in-out;

  .close {
    top: 15px;
    right: 15px;
  }

  .reference {
    @apply mb-7 bg-black backdrop-blur-lg;
    background-color: rgba(0, 0, 0, 0.6);
  }

  &._hidden {
    left: -440px;
  }
}

.rightModal {
  position: absolute;
  z-index: 1010;
  top: 0;
  right: 0;
  bottom: 0;
  width: 512px;
  transition: all 0.3s ease-in-out;

  .close {
    top: 15px;
    right: 15px;
  }

  &._hidden {
    right: -512px;
  }
}