@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-white;
  }
}

@import "ui.scss";

html {
  overflow: hidden;
}

body {
  height: 100vh;
  overflow: hidden;
}

.menu {
  @apply backdrop-blur-lg;
  background-color: rgba(255, 255, 255, 0.2);
  width: 40rem;
  height: 40rem;
  border-radius: 20rem;
  position: absolute;
  top: -20rem;
  left: -20rem;
  z-index: 900;
  opacity: 1;

  .wrap {
    transform: translateY(-50%);
    position: absolute;
    top: 28rem;
    left: 22rem;
    right: 5rem;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    transition: all 0.4s ease-in-out;
  }

  transition: all 0.4s ease-in-out;

  &.folded {
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) rotate(-90deg);

    .wrap {}
  }
}

.menu-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 800;

  &.folded {
    display: none;
  }
}

.papyrus-fixed {
  background-image: url(./assets/papyrus.jpg);
  background-size: cover;
  position: relative;
  overflow: hidden;

  .tl {
    z-index: 1;
    content: "";
    width: 166px;
    height: 129px;
    background-image: url(./assets/tl.png);
    background-size: contain;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .tr {
    z-index: 1;
    content: "";
    width: 166px;
    height: 129px;
    background-image: url(./assets/tr.png);
    background-size: contain;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .bl {
    z-index: 1;
    content: "";
    width: 166px;
    height: 129px;
    background-image: url(./assets/bl.png);
    background-size: contain;
    position: absolute;
    bottom: 10px;
    left: 10px;
  }

  .br {
    z-index: 1;
    content: "";
    width: 166px;
    height: 129px;
    background-image: url(./assets/br.png);
    background-size: contain;
    position: absolute;
    bottom: 10px;
    right: 10px;
  }

  .winds {
    position: absolute;
    width: 100vw;
    height: 100vh;
    right: 0;
    background: url(./assets/winds2.svg) center left no-repeat;
    background-size: cover;
  }
  .rest {
    position: absolute;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.screen.login {
  .logo {
    width: 664px;
    height: 285px;
  }

  .separator {
    width: 268px;
    height: 41px;
    background-image: url(./assets/separator.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.screen.welcome {
  .logo {
    width: 664px;
    height: 285px;
  }

  .body {
    position: relative;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.question {
  background-size: cover;
  overflow: hidden;

  // .window {
  //   top: 110px;
  //   left: 95px;
  //   right: 95px;
  //   bottom: 160px;
  // }
}

.question>.leftbar,
.rest>.leftbar {

  .menu-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-bottom: 10px;
    }

    .references {
      width: 50px;
      height: 40px;
      border-radius: 20px;
      margin-bottom: 10px;
    }

    .acronyms {
      width: 50px;
      height: 40px;
      border-radius: 20px;
    }



    .user {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
}

.counter {
  border-radius: 20px;
  height: 40px;
}

// DEPRECATED HEADER
.question>.header,
.rest>.header {
  background-image: url(./assets/centerlogo.png);
  background-size: 359px 91px;
  background-position: center top;
  background-repeat: no-repeat;
  height: 91px;
  background-color: red;

  .menu-buttons {
    display: flex;
    flex-direction: column;

    .main {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-bottom: 10px;
    }

    .references {
      width: 50px;
      height: 40px;
      border-radius: 20px;
      margin-bottom: 10px;
    }

    .acronyms {
      width: 50px;
      height: 40px;
      border-radius: 20px;
    }

    .counter {
      border-radius: 20px;
      margin-right: 10px;
      height: 40px;
    }

    .user {
      width: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
}